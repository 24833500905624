const NODE_ENV = process.env.NODE_ENV || 'development';

export const getEnvironment = () => {
  if (NODE_ENV === 'development') {
    return 'local';
  }
  // dev
  if (window.location.hostname.includes('dev')) {
    return 'dev';
  }
  // test
  if (window.location.hostname.includes('test')) {
    return 'test';
  }
  // demo
  if (window.location.hostname.includes('demo')) {
    return 'demo';
  }
  // production
  return 'prod';
};

const getAuth0Domain = () => {
  const env = getEnvironment();

  switch (env) {
    case 'local':
      return 'incidentxpressdev.us.auth0.com';
    case 'dev':
      return 'incidentxpressdev.us.auth0.com';
    case 'test':
      return 'incidentxpressdev.us.auth0.com';
    case 'demo':
      return 'incidentxpressdev.us.auth0.com';
    default:
      // production
      return 'incidentxpressprod.us.auth0.com';
  }
};

const getClientId = () => {
  const env = getEnvironment();

  switch (env) {
    case 'local':
      return `V2LjZ6vRMxB0g8u4Vf52kBU2wa6pLsOV`;
    case 'dev':
      return `V2LjZ6vRMxB0g8u4Vf52kBU2wa6pLsOV`;
    case 'test':
      return `RBkbcKVuleMhZjzgFKQpzMtqh3Tw62Qd`;
    case 'demo':
      return `9LnzqVuvphfHC6h08y4hnsRkZWsC0z2b`;
    default:
      // production
      return 'om3GMaCvw0lNj2r3JJplfSJAs73DTVb0';
  }
};

const getApiUrl = () => {
  const env = getEnvironment();

  switch (env) {
    case 'local':
      return `${window.location.protocol}//${window.location.hostname}:5000`;
    case 'dev':
      return 'https://api-dev.incidentxpress.com';
    case 'test':
      return 'https://api-test.incidentxpress.com';
    case 'demo':
      return 'https://api-demo.incidentxpress.com';
    default:
      // production
      return 'https://api.incidentxpress.com';
  }
};

const getReportServerUrl = () => {
  const env = getEnvironment();

  switch (env) {
    case 'local':
      return 'https://report2.incidentxpress.com';
    // return 'http://localhost:5000';
    case 'dev':
      return 'https://incident-xpress-report.azurewebsites.net';
    case 'test':
      return 'https://report2.incidentxpress.com';
    case 'demo':
      return 'https://report2.incidentxpress.com';
    default:
      // production
      return 'https://report2.incidentxpress.com';
  }
};

const getStripePubKey = () => {
  const env = getEnvironment();

  switch (env) {
    case 'local':
      return 'pk_test_mkWuV2ON7Xpr3cMrns3WwhEk00tHre0f9t';
    case 'dev':
      return 'pk_test_mkWuV2ON7Xpr3cMrns3WwhEk00tHre0f9t';
    case 'test':
      return 'pk_test_mkWuV2ON7Xpr3cMrns3WwhEk00tHre0f9t';
    case 'demo':
      return 'pk_test_mkWuV2ON7Xpr3cMrns3WwhEk00tHre0f9t';
    default:
      // production
      return 'pk_live_7545AuaA16jPuUkqryLblAA400rIOAGjcz';
  }
};

const getStorateUrl = () => {
  const env = getEnvironment();

  switch (env) {
    case 'local':
      return 'https://incidentxpresstest.azureedge.net';
    case 'dev':
      return 'https://incidentxpresstest.azureedge.net';
    case 'test':
      return 'https://incidentxpresstest.azureedge.net';
    case 'demo':
      return 'https://incidentxpressdemo.blob.core.windows.net';
    default:
      // production
      return 'https://incidentxpresscdn.azureedge.net';
  }
};

const AUTH0_DOMAIN = getAuth0Domain();
const AUTH0_CLIENT_ID = getClientId();
const AUTH0_AUDIENCE = 'https://api.incidentxpress.com';
const AUTH0_CALLBACK_URL = `${window.location.origin}/callback`;

const APP_NAME = 'incident-xpress-web';

const STRIPE_PUB_KEY = getStripePubKey();
const API_URL = getApiUrl();
const REPORT_SERVER_URL = getReportServerUrl();
const AZURE_STORAGE_RUL = getStorateUrl();
const INCIDENT_XPRESS_SITE_URL = 'https://www.incidentxpress.com';

const config = {
  app: {
    name: APP_NAME,
    env: getEnvironment(),
  },
  auth0: {
    domain: AUTH0_DOMAIN,
    clientId: AUTH0_CLIENT_ID,
    audience: AUTH0_AUDIENCE,
    callbackUrl: AUTH0_CALLBACK_URL,
  },
  stripe: {
    pubKey: STRIPE_PUB_KEY,
  },
  url: {
    reportServerUrl: REPORT_SERVER_URL,
    apiUrl: API_URL,
    fileStorageUrl: AZURE_STORAGE_RUL,
    incidentXpressSiteUrl: INCIDENT_XPRESS_SITE_URL,
  },
};

export default config;
