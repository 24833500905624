import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
  Typography,
  Button,
  Select,
  MenuItem,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import _ from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
} from '@material-ui/pickers';
import { DefaultDateFormat, DateSearchPeriodType } from '../../constants';
import { useMediaInfo } from '../../utils/hooks';
import HelpButton from '../../components/buttons/HelpButton';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: 0,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(-1),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1, 0, 0, 1),
    },
  },
  searchFields: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}));

const monthArray = moment.monthsShort();
const dayOfWeekArray = moment.weekdaysShort();

const DateSearchTypeOption = props => {
  const today = moment().endOf('day');
  const defaultPeriod = 'Custom';

  const classes = useStyles();
  const [state, setState] = React.useState({
    fromDate: today
      .clone()
      .add(-1, 'day')
      .startOf('day')
      .format(DefaultDateFormat().dateTimeISO),
    toDate: today.format(DefaultDateFormat().dateTimeISO),
    period: defaultPeriod,
    periodType: DateSearchPeriodType.period,
    months: [],
    dayOfWeeks: [],
    days: '30',
    lastNMonths: '0',
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const { isSmaller, isMobile } = useMediaInfo({ minBreakPoint: 'sm' });

  const { title } = props;
  // const { fromDate, toDate, period, periodType } = state;

  const inputHandleChange = event => {
    const { name, value } = event.target;
    let fromDate = state.fromDate;
    let toDate = state.toDate;
    let days = state.days;
    let lastNMonths = state.lastNMonths;

    if (name === 'period') {
      toDate = today.format(DefaultDateFormat().dateTimeISO);
      if (value === '30') {
        fromDate = today
          .clone()
          .add(-30, 'day')
          .startOf('day')
          .format(DefaultDateFormat().dateTimeISO);
      } else if (value === '90') {
        fromDate = today
          .clone()
          .add(-90, 'day')
          .startOf('day')
          .format(DefaultDateFormat().dateTimeISO);
      } else if (value === '180') {
        fromDate = today
          .clone()
          .add(-180, 'day')
          .startOf('day')
          .format(DefaultDateFormat().dateTimeISO);
      }
    } else if (name === 'lastNDays') {
      days = value;
    } else if (name === 'lastNMonths') {
      lastNMonths = value;
    }
    setState({
      ...state,
      [event.target.name]: event.target.value,
      toDate,
      fromDate,
      days,
      lastNMonths,
    });
  };

  const monthHandleChange = (event, checked) => {
    let months = state.months;
    const index = parseInt(event.target.name.replace('month', ''), 10);
    if (checked) {
      months = [...months, index];
    } else {
      months = months.filter(month => month !== index);
    }

    months = _.orderBy(months);

    setState({ ...state, months });
  };

  const weekdaysHandleChange = (event, checked) => {
    let dayOfWeeks = state.dayOfWeeks;
    const index = parseInt(event.target.name.replace('weekday', ''), 10);
    if (checked) {
      dayOfWeeks = [...dayOfWeeks, index];
    } else {
      dayOfWeeks = dayOfWeeks.filter(weekday => weekday !== index);
    }

    dayOfWeeks = _.orderBy(dayOfWeeks);

    setState({ ...state, dayOfWeeks });
  };

  const addSearchCriteria = () => {
    const {
      periodType,
      fromDate,
      toDate,
      months,
      dayOfWeeks,
      days,
      lastNMonths,
    } = state;
    const option = {
      id: uuid(),
      periodType,
      fromDate,
      toDate,
      months,
      dayOfWeeks,
      days,
      lastNMonths: Number(lastNMonths).valueOf(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    if (periodType === DateSearchPeriodType.month && months.length === 0) {
      return;
    }
    if (
      periodType === DateSearchPeriodType.dayOfWeek &&
      dayOfWeeks.length === 0
    ) {
      return;
    }

    props.onSearchCriteriaAdded(option);
  };

  const handleDateToChange = date => {
    setState({ ...state, toDate: date });
  };

  const handleDateFromChange = date => {
    setState({ ...state, fromDate: date });
  };

  const isDaysError = () => {
    return !new RegExp('^[1-9][1-9]*').test(state.days);
  };

  const isLastNMonthsError = () => {
    return !new RegExp('^[0-9][0-9]*').test(state.lastNMonths);
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography variant="h6">{title}</Typography>
        <div className={classes.lineItem}>
          <FormControl component="fieldset" className={classes.formControl}>
            {isSmaller ? (
              <Select
                name="periodType"
                value={state.periodType}
                onChange={inputHandleChange}
              >
                <MenuItem value={DateSearchPeriodType.period}>Period</MenuItem>
                <MenuItem value={DateSearchPeriodType.month}>Month</MenuItem>
                <MenuItem value={DateSearchPeriodType.lastNDays}>
                  Last n days
                </MenuItem>
                <MenuItem value={DateSearchPeriodType.lastNMonths}>
                  Last n months
                </MenuItem>
                <MenuItem value={DateSearchPeriodType.dayOfWeek}>
                  Day of week
                </MenuItem>
                <MenuItem value={DateSearchPeriodType.workShiftPeriod}>
                  Work shift period
                </MenuItem>
              </Select>
            ) : (
              <RadioGroup
                aria-label="periodType"
                name="periodType"
                className={classes.group}
                value={state.periodType}
                onChange={inputHandleChange}
                row
              >
                <FormControlLabel
                  value={DateSearchPeriodType.period}
                  control={<Radio />}
                  label="Period"
                />
                <FormControlLabel
                  value={DateSearchPeriodType.lastNDays}
                  control={<Radio />}
                  label={
                    <div className={classes.label}>
                      Last n days
                      <HelpButton message="When selecting 'Last n days', the time period pulled will be from the current date back n days." />
                    </div>
                  }
                />
                <FormControlLabel
                  value={DateSearchPeriodType.lastNMonths}
                  control={<Radio />}
                  label={<div className={classes.label}>Last n months</div>}
                />
                <FormControlLabel
                  value={DateSearchPeriodType.month}
                  control={<Radio />}
                  label="Month"
                />
                <FormControlLabel
                  value={DateSearchPeriodType.dayOfWeek}
                  control={<Radio />}
                  label="Day of week"
                />
                <FormControlLabel
                  value={DateSearchPeriodType.workShiftPeriod}
                  control={<Radio />}
                  label="Work shift period"
                />
              </RadioGroup>
            )}
          </FormControl>
        </div>
        {state.periodType === DateSearchPeriodType.period && (
          <React.Fragment>
            <FormLabel className={classes.label}>Period</FormLabel>
            <div className={classes.lineItem}>
              {/* <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  aria-label="Period"
                  name="period"
                  className={classes.group}
                  value={state.period}
                  onChange={inputHandleChange}
                  row
                >
                  <FormControlLabel
                    value="30"
                    control={<Radio />}
                    label="Last 30 days"
                  />
                  <FormControlLabel
                    value="90"
                    control={<Radio />}
                    label="Last 90 days"
                  />
                  <FormControlLabel
                    value="180"
                    control={<Radio />}
                    label="Last 180 days"
                  />
                  <FormControlLabel
                    value="Custom"
                    control={<Radio />}
                    label="Custom"
                  />
                </RadioGroup>
              </FormControl> */}
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    label="Date from"
                    format={DefaultDateFormat(props.dateFormat).date}
                    value={state.fromDate}
                    onChange={handleDateFromChange}
                    disabled={state.period !== 'Custom'}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    label="Date to"
                    format={DefaultDateFormat(props.dateFormat).date}
                    value={state.toDate}
                    onChange={handleDateToChange}
                    disabled={state.period !== 'Custom'}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </div>
          </React.Fragment>
        )}

        {state.periodType === DateSearchPeriodType.workShiftPeriod && (
          <React.Fragment>
            <FormLabel className={classes.label}>Work shift period</FormLabel>
            <div className={classes.lineItem}>
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TimePicker
                    label="Time from"
                    format={DefaultDateFormat(props.dateFormat).time}
                    value={state.fromDate}
                    onChange={handleDateFromChange}
                    disabled={state.period !== 'Custom'}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TimePicker
                    label="Time to"
                    format={DefaultDateFormat(props.dateFormat).time}
                    value={state.toDate}
                    onChange={handleDateToChange}
                    disabled={state.period !== 'Custom'}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </div>
          </React.Fragment>
        )}

        {state.periodType === DateSearchPeriodType.lastNDays && (
          <React.Fragment>
            <FormLabel className={classes.label}>Period</FormLabel>
            <div className={classes.lineItem}>
              <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  aria-label="Last n days"
                  name="lastNDays"
                  className={classes.group}
                  value={state.days}
                  onChange={inputHandleChange}
                  row
                >
                  <FormControlLabel
                    value="30"
                    control={<Radio />}
                    label="Last 30 days"
                  />
                  <FormControlLabel
                    value="90"
                    control={<Radio />}
                    label="Last 90 days"
                  />
                  <FormControlLabel
                    value="180"
                    control={<Radio />}
                    label="Last 180 days"
                  />
                  <FormControlLabel
                    value="365"
                    control={<Radio />}
                    label="Last 365 days"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  name="days"
                  label="Days"
                  value={state.days || ''}
                  onChange={event => {
                    setState({ ...state, days: event.target.value });
                  }}
                  margin="none"
                  // onBlur={handleFormBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={isDaysError()}
                  helperText={
                    isDaysError()
                      ? 'Please enter a number greater than 0.'
                      : undefined
                  }
                  inputProps={{ autoComplete: 0 }}
                />
              </FormControl>
            </div>
          </React.Fragment>
        )}

        {state.periodType === DateSearchPeriodType.lastNMonths && (
          <React.Fragment>
            <FormLabel className={classes.label}>Period</FormLabel>
            <div className={classes.lineItem}>
              <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  aria-label="Last n months"
                  name="lastNMonths"
                  className={classes.group}
                  value={state.lastNMonths}
                  onChange={inputHandleChange}
                  row
                >
                  <Tooltip title={moment().format('MMMM')} placement="bottom">
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="Current month"
                    />
                  </Tooltip>

                  <Tooltip
                    title={moment()
                      .add(-1, 'month')
                      .format('MMMM')}
                    placement="bottom"
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Previous month"
                    />
                  </Tooltip>

                  <Tooltip
                    title={`${moment()
                      .add(-3, 'month')
                      .format('MMM')} - ${moment()
                      .add(-1, 'month')
                      .format('MMM')}`}
                    placement="bottom"
                  >
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="Last 2 months"
                    />
                  </Tooltip>

                  <Tooltip
                    title={`${moment()
                      .add(-4, 'month')
                      .format('MMMM')} - ${moment()
                      .add(-1, 'month')
                      .format('MMMM')}`}
                    placement="bottom"
                  >
                    <FormControlLabel
                      value="3"
                      control={<Radio />}
                      label="Last 3 months"
                    />
                  </Tooltip>

                  <Tooltip
                    title={`${moment()
                      .add(-13, 'month')
                      .format('MMM YYYY')} - ${moment()
                      .add(-1, 'month')
                      .format('MMM YYYY')}`}
                    placement="bottom"
                  >
                    <FormControlLabel
                      value="12"
                      control={<Radio />}
                      label="Last 12 months"
                    />
                  </Tooltip>
                </RadioGroup>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  name="lastNMonths"
                  label="Months"
                  value={state.lastNMonths || ''}
                  onChange={event => {
                    setState({ ...state, lastNMonths: event.target.value });
                  }}
                  margin="none"
                  // onBlur={handleFormBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={isLastNMonthsError()}
                  helperText={
                    isLastNMonthsError()
                      ? 'Please enter a number greater than or equalt to 0.'
                      : undefined
                  }
                  inputProps={{ autoComplete: 0 }}
                />
              </FormControl>
            </div>
          </React.Fragment>
        )}

        {state.periodType === DateSearchPeriodType.month && (
          <React.Fragment>
            <FormLabel className={classes.label}>Month</FormLabel>
            <div className={classes.lineItem}>
              <FormGroup row>
                {monthArray.map((month, index) => (
                  <FormControlLabel
                    key={month}
                    control={
                      <Checkbox
                        name={`month${index + 1}`}
                        checked={state[`month${index + 1}`]}
                        onChange={monthHandleChange}
                        color="primary"
                      />
                    }
                    label={month}
                  />
                ))}
              </FormGroup>
            </div>
          </React.Fragment>
        )}

        {state.periodType === DateSearchPeriodType.dayOfWeek && (
          <React.Fragment>
            <FormLabel className={classes.label}>Day of Week</FormLabel>
            <div className={classes.lineItem}>
              <FormGroup row>
                {dayOfWeekArray.map((weekday, index) => (
                  <FormControlLabel
                    key={weekday}
                    control={
                      <Checkbox
                        name={`weekday${index + 1}`}
                        checked={state[`weekday${index + 1}`]}
                        onChange={weekdaysHandleChange}
                        color="primary"
                      />
                    }
                    label={weekday}
                  />
                ))}
              </FormGroup>
            </div>
          </React.Fragment>
        )}
        <div className={classes.searchFields}>
          <FormControl className={classes.formControl}>
            <Button
              variant="contained"
              color="primary"
              className={classes.searchButton}
              onClick={addSearchCriteria}
            >
              Add Search Criteria
            </Button>
          </FormControl>
        </div>
      </div>
    </React.Fragment>
  );
};

DateSearchTypeOption.propTypes = {
  title: PropTypes.string.isRequired,
  onSearchCriteriaAdded: PropTypes.func.isRequired,
  dateFormat: PropTypes.oneOf([0, 1]).isRequired, // AccountDateFormatEnum
};

DateSearchTypeOption.defaultProps = {
  onSearchCriteriaAdded: () => {},
};

export default DateSearchTypeOption;
